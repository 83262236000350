//
// index.tsx - Nodes related functionality
//

import { FetchHookResult } from "@data-types/generic-hook-type";
import { Node, Nodes, NodeType } from "@data-types/nodes-types";
import { renderAnalyzer, swrFetcher } from "@lib/client-side";
import useSWR from "swr";

// Types

// Utils

/**
 * Determines the style attributes for a node based on its type and working status.
 *
 * @param { Node } node - The node object with type and match_leader properties.
 * @param {any} theme - The theme object containing color palette information.
 * @returns {{
 *   color: string;
 *   type: string;
 * }} An object representing the color and tooltip message for the node type.
 */
export function styleNodeBasedOnType(
  node: Node,
  theme: any
): { color: string; type: string } {
  let nodeType = node.type;
  const nodeMatchLeader = node.match_leader;
  let nodeIndicator: { color: string; type: string };

  if (!nodeMatchLeader) {
    nodeType = "not working";
  } else {
    nodeType = nodeType
      ? (nodeType.toLowerCase() as NodeType)
      : ("undefined" as NodeType);
  }

  switch (nodeType) {
    case "not working":
      nodeIndicator = {
        color: theme.palette.error.light,
        type: "Node isn't working.",
      };
      break;
    case "leader":
      nodeIndicator = {
        color: theme.palette.success.main,
        type: "Node is leader.",
      };
      break;
    case "follower":
      nodeIndicator = {
        color: theme.palette.secondary.main,
        type: "Node is a follower.",
      };
      break;
    case "learner":
      nodeIndicator = {
        color: theme.palette.info.light,
        type: "Node is a learner.",
      };
      break;
    case "undefined":
      nodeIndicator = {
        color: theme.palette.info.light,
        type: "Node type not defined.",
      };
      break;
    default:
      nodeIndicator = {
        color: theme.palette.error.light,
        type: "Node type not defined.",
      };
  }

  return nodeIndicator;
}

/**
 * Checks if a node is working based on its match_leader property.
 *
 * @param { Node | undefined } node - The node object to check.
 * @returns {boolean} Returns `true` if the node is working (i.e., match_leader > 0), otherwise `false`.
 */
export function nodeIsWorking(node?: Node): boolean {
  // Check if node exists and has a match_leader value greater than 0
  return node && node.match_leader && node.match_leader > 0 ? true : false;
}

/**
 * Extracts the UUID from a node's address.
 *
 * @param { Node | undefined } node - The node object containing the address.
 * @returns {string} The extracted UUID or "unknown" if the address is unavailable.
 */
export function getNodeUUID(node?: Node): string {
  if (node?.address) {
    const address = node.address;
    const firstPointIndex = address.indexOf(".");

    // Extract the substring from the start of the address to the first period
    const uuid = address.substring(0, firstPointIndex);
    return uuid;
  } else {
    return "unknown";
  }
}

/**
 * Extracts the location from a node's region string, assuming the location is enclosed in parentheses.
 *
 * @param { Node | undefined } node - The node object containing the region information.
 * @returns {string} The extracted location or "unknown" if not found.
 */
export function getNodeLocation(node?: Node): string {
  if (node?.region) {
    const region = node.region;
    const regex = /\((.*?)\)/;

    // Match the location within parentheses in the region string
    const matches = region.match(regex);
    if (matches) {
      const location = matches[1];
      return location;
    } else {
      return "unknown";
    }
  } else {
    return "unknown";
  }
}

/**
 * Extracts the leader node from a list of nodes.
 *
 * @param {Nodes} nodes - The list of nodes to search.
 * @returns {Node | undefined} The leader node, or `undefined` if no leader is found.
 */
export function getLeaderNode(nodes: Nodes): Node | undefined {
  return nodes.find((node) => node.type.toLowerCase() === "leader");
}

// Hooks

/**
 * Custom hook to fetch and analyze node data for a given project ID.
 *
 * This hook retrieves node data from the API, analyzes it to ensure a consistent structure,
 * and provides various states for managing the display in the UI, such as loading, error, and validation.
 *
 * @param {string} projectId - The ID of the project to fetch node data for.
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing nodes data.
 *
 * @returns {FetchHookResult<Nodes>} An object containing:
 *   - `data`: The list of nodes for the project, or `undefined` if no data is available.
 *   - `isLoading`: `true` if the data is currently loading; otherwise, `false`.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating; otherwise, `false`.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is node data available.
 *   - `emptyData`: `true` if node data is available but empty.
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useGetProjectNodes(
  projectId: string,
  refreshInterval?: number
): FetchHookResult<Nodes> {
  // Fetch nodes data with SWR based on the project ID
  const { data, error, isValidating, mutate } = useSWR(
    () =>
      projectId && [`/api/projects/${projectId}/nodes`, "useGetProjectNodes"],
    swrFetcher,
    {
      refreshInterval: refreshInterval ?? 0,
      revalidateOnFocus: false, // Avoid revalidating data when the window regains focus
      revalidateIfStale: false, // Prevent revalidation if the data is stale
    }
  );

  // Use renderAnalyzer to get data status indicators for display
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    true
  );

  return {
    data: data?.value,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    mutate,
  };
}
